import React ,{useEffect,useState} from "react";
import { Grid,Radio,FormControlLabel,RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import { useUserDispatch, signOut } from "../../context/UserContext";
// components

import {encode , decode } from 'jwt-simple';
// data


const columns = [
  { label: "TranID", name: "TranID" }, { label: "Phone", name: "Msisdn" },
  { label: "Amount", name: "amount", options: {
    customBodyRender: value => <span>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
  } }, { label: "Created", name: "created" },{ label: "Fee", name: "Fee" ,options: {
    customBodyRender: value => <span>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
  } } ,{ label: "resultDesc", name: "resultDesc" },
  ];


const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  }
}))

export default function History(props) {
  const classes = useStyles();
  const secrect = 'Mm0ney@2021%#|LMM58972N';  
  var userDispatch = useUserDispatch();
  const Users = props;
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  const[historyCashout,sethistoryCashout] = useState(null);
  

  const [value, setValue] = React.useState('CASHIN');
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    // Good!
    
    const History = async () =>{
      const param = {
        "userid": Users.data.userid,
        "service":  value,
        "key" : Users.data.key               
      }
      console.log('parm', param)
      const encode_param = encode(param,secrect,'HS256');
      const data= {
                    
        "data":encode_param

      }

     // console.log(param)
    //console.log(data);
    console.log(data);
    const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
      };
      await fetch('https://agentapi.mmoney.la/PostSale_Transaction_V2', requestOptions)
      .then((response) =>  response.json())
      .then((responsejson) =>{
       
        const result =decode(responsejson.data,secrect, 'HS256');
       //console.log('return',result.result.length)
        if(result.resultCode=="200"){
                       
          //console.log('return',result.result)
          sethistoryCashout(result.result);
         
            
    
        }
        else if(result.resultCode=="202"){

          signOut(userDispatch, props.history)
        }
        else{
    
          console.log(JSON.stringify(result));
         //toast.error(result.resultDes);
         
         // dispatch({ type: "LOGIN_FAILURE" });
          setError(true);
          setIsLoading(false);
        }    
  
      });
      
      
}
History();

  }, [value]);

  const options = {
   
    selectableRows: false 
   
  };

 

  return (
    <>
 
      <RadioGroup row aria-label="position" name="gender1" value={value} onChange={handleChange}>
        <FormControlLabel value="CASHIN" control={<Radio />} label="CASHIN" />
        <FormControlLabel value="CASHOUT" control={<Radio />} label="CASHOUT" />        
      </RadioGroup>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          {historyCashout != undefined &&
          <MUIDataTable
            title={value}
       
            data={historyCashout}
            columns={columns}
            options={options}
            
          />
          }

        </Grid>

        


       
      </Grid>
    </>
  );
}
