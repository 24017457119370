import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Link
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import {encode , decode } from 'jwt-simple';
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.png";

// context


function Forgot(props) {
  var classes = useStyles();

  // global


  // local
  
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);;
  var [loginValue, setLoginValue] = useState("");
  var [otpValue, setotp] = useState("");
  var [otpstatus, setotpstatus] = useState(false);


  function SendOTP(){
    setIsLoading(true) 
    const data =  { "usercode": loginValue }; 
    const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  
   fetch('/Request_OTP_V2', requestOptions)
    .then((response) => response.json())
    .then((responsejson) =>{
     
      const result =responsejson;     
      if(result.resultCode=="200"){
                 
          setIsLoading(false);        
          setotpstatus(true);  
      }
      else{
        setIsLoading(false);
        
           
        
      }    

    });


  }

  function ConfirmOTP(){
    setIsLoading(true)  
    const data =  { "usercode": loginValue ,"sms_otp": otpValue   };  
    
      const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
      };

      fetch('/Confirm_OTP_V2', requestOptions)
      .then((response) => response.json())
      .then((responsejson) =>{
      
        const result =responsejson      
     
        if(result.resultCode=="200"){
          
          
            setIsLoading(false)
            props.history.push(        
            {
              pathname: "/resetPassword",          
              state: {usercode :loginValue,opt : otpValue}
            });
            

        }
        else{
          setIsLoading(false);
          alert(result.resultDesc)          
          setotpstatus(false);
        }    

      });

  }



  return (
    <Grid container className={classes.container}>

      <div className={classes.logotypeContainer}>
      
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>M-Money Agent</Typography>
      </div>
      <div className={classes.formContainer}> 
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Forgot Password" classes={{ root: classes.tab }} />
          
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
                            
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
              {!otpstatus ? (
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Username"
                type="text"
                fullWidth
              />) : (
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={otpValue}
                onChange={e => setotp(e.target.value)}
                margin="normal"
                placeholder="OTP"
                type="number"
                fullWidth
              />) 
                }

              
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                
                
                  <Button
                    disabled={
                      loginValue.length === 0 && otpValue.length === 0
                    }
                    onClick={otpstatus==false ? SendOTP : ConfirmOTP}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Submit
                  </Button>
                  
                )}
                 <Link href="#login"  variant="body2">
                  Back to Login
                </Link>
                
              </div>
            </React.Fragment>
          )}
        
           
        
        </div>
        <Typography color="primary" className={classes.copyright}>
        © 2020-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://mmoney.la" rel="noopener noreferrer" target="_blank">Lao Mobile Money</a>, LLC. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Forgot);
