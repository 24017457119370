import React, {useEffect,useState} from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  BarChart,
  Legend,
  Bar,
  Tooltip,
  CartesianGrid,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";

// components

import Widget from "../../components/Widget";

import { Typography } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import { ToastContainer, toast } from "react-toastify";
import { useUserDispatch, signOut } from "../../context/UserContext";




export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  const Users = props;
  var userDispatch = useUserDispatch();
  const [PieChartData,setPieChartData] = useState(null);
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  const[resultCashing,setCashing] = useState(null);
  const [mainChartData,setmainChartData] = useState(null);

  // local
  var [mainChartState, setMainChartState] = useState("daily");

  useEffect(() => {
    // Good!
  
    const Dashboard =  () =>{
      const data = {
        "userid": Users.data.userid,
        "key" : Users.data.key
       
               
      }
      

    
    const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
      };
       fetch('/PostSum_Transaction_V2', requestOptions)
      .then((response) =>  response.json())
      .then((responsejson) =>{
       
        const result =responsejson;
        //console.log('return',result)
        if(result.resultCode=="200"){
                       
          console.log('dashboard',result)
          setCashing(result)   
          var s = [{ name: "Sub Total", value: result.Sub, color: "secondary" },
          { name: "Grand Total", value: result.CashOut_Total + result.CashIn_Total, color: "success" },
          { name: "CashIn Total", value: result.CashIn_Total, color: "warning" },
          { name: "CashOut Total", value: result.CashOut_Total, color: "primary" }
        ];

         setPieChartData(s)
         setmainChartData(result.daily)        
        
         
         
            
    
        }
        else if(result.resultCode=="202"){

          signOut(userDispatch, props.history)
        }
        else{
    
        //  console.log(JSON.stringify(result));
          toast.error(result.resultDes);
         
         // dispatch({ type: "LOGIN_FAILURE" });
          setError(true);
          setIsLoading(false);
        }    
  
      });
      
      
}
Dashboard();

  }, []);



  return (
    <>
      { (resultCashing!= null && PieChartData !=null) &&
      <Grid container spacing={4}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title="Grand Total"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
              <Typography size="xl" weight="medium" noWrap>
                {(resultCashing.CashOut_Total + resultCashing.CashIn_Total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') } ₭
              </Typography>
                </Grid>
                
              </Grid>
            </div>
            
          </Widget>
        </Grid>
        <Grid item lg={3} md={8} sm={6} xs={12}>
          <Widget
            title="Cashing"
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <div className={classes.performanceLegendWrapper}>
              <div className={classes.legendElement}>
                <Dot color="warning" />
                <Typography
                  color="text"
                  colorBrightness="secondary"
                  className={classes.legendElementText}
                >
                  CashIn
                </Typography>
              </div>
              <div className={classes.legendElement}>
                <Dot color="primary" />
                <Typography
                  color="text"
                  colorBrightness="secondary"
                  className={classes.legendElementText}
                >
                  CashOut
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                size="md"
                color="text"
                colorBrightness="secondary"
                className={classes.progressSectionTitle}
              >
                {resultCashing.CashOut_Total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ₭
              </Typography>
              <LinearProgress
                variant="determinate"
                value={parseInt((resultCashing.CashOut_Total* 100)/Users.data.limit_out)}
                classes={{ barColorPrimary: classes.progressBarWarning }}
                className={classes.progress}
              />
            </div>
            <div className={classes.progressSection}>
              <Typography
                size="md"
                color="text"
                colorBrightness="secondary"
                className={classes.progressSectionTitle}
              >
                {resultCashing.CashIn_Total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ₭
              </Typography>
              <LinearProgress
                variant="determinate"
                value={parseInt((resultCashing.CashIn_Total* 100)/Users.data.limit_in)}
                classes={{ barColorPrimary: classes.progressBarPrimary }}
                className={classes.progress}
              />
            </div>
            
          </Widget>
        </Grid>
        
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Widget title="Revenue" upperTitle className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ResponsiveContainer width="100%" height={144}>
                  <PieChart>
                    <Pie
                      data={PieChartData}
                      innerRadius={50}
                      outerRadius={60}
                      dataKey="value"
                    >
                      {PieChartData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={theme.palette[entry.color].main}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.pieChartLegendWrapper}>
                  {PieChartData.map(({ name, value, color }, index) => (
                    <div key={color} className={classes.legendItemContainer}>
                      <Dot color={color} />
                      <Typography style={{ whiteSpace: "nowrap", fontSize: 18 }} >
                        &nbsp;{name}&nbsp;
                      </Typography>
                      <Typography color="text" style={{ color:"grey", whiteSpace: "nowrap", fontSize: 18 }} >
                        &nbsp;{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h5"
                  color="text"
                  colorBrightness="secondary"
                >
                  Daily Line Chart
                </Typography>
                <div className={classes.mainChartHeaderLabels}>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="warning" />
                    <Typography className={classes.mainChartLegentElement}>
                      CashIn
                    </Typography>
                  </div>
                  <div className={classes.mainChartHeaderLabel}>
                    <Dot color="primary" />
                    <Typography className={classes.mainChartLegentElement}>
                      CashOut
                    </Typography>
                  </div>
                 
                </div>
                <Select
                  value={mainChartState}
                  onChange={e => setMainChartState(e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: classes.mainChartSelectRoot,
                        input: classes.mainChartSelect,
                      }}
                    />
                  }
                  autoWidth
                >
                  <MenuItem value="daily">Daily</MenuItem>            
               
                </Select>
              </div>
            }
          >
          
          { mainChartData != null &&
          <div>
            
          <BarChart width={730} height={250} data={mainChartData.cashIn}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Dates" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Total" fill="#F1B727" />          
          </BarChart>


              <BarChart width={730} height={250} data={mainChartData.cashOut}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Dates" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Total" fill="#436BE6" />
                
                </BarChart>
              </div>
            }
          

          </Widget>
        </Grid>
        
        
      </Grid>
      }
    </>
  );
}

// #######################################################################


