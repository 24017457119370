import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  History as HistoryIcon,
  Assignment as AssignmentIcon  
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";


// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

var structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },  
  {
    id: 1,
    label: "Cash In",
    link: "/app/cashin",
    icon: <ArrowDownwardIcon />,
  },
  {
    id: 2,
    label: "Cash Out",
    link: "/app/cashout",
    icon: <ArrowUpwardIcon />,
  },
  {
    id: 3,
    label: "History",
    link: "/app/history",
    icon: <HistoryIcon />,
  },
  // {
  //   id: 4,
  //   label: "Report",
  //   link: "/app/report",
  //   icon: <AssignmentIcon />,
  // },  
  { id: 5, type: "divider" },
  { id: 6, type: "title", label: "HELP" },
  { id: 7, label: "about", link: "https://mmoney.la", icon: <LibraryIcon /> },

  
];
///const Users = JSON.parse(localStorage.getItem('users'));
function Sidebar({ location,data }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var Users = data;
  
  console.log('sidebar',location);
  // local
  var [isPermanent, setPermanent] = useState(true);
  var [Listmenu, setListmenu] = useState(null);

  useEffect(function() {
    
     function fetchMyAPI() {
      var od=[];   
      let result = null;
      structure.forEach(element => {

      if(element.id==0 || element.id==5 || element.id==6 || element.id==7 ){

        od.push(element);
      }
      Users.menuid.forEach(data => {
        console.log('menu_pro',element)
        if(element.id==data.id){
          od.push(element);
          
        }
        
      
      });

    
       setListmenu(od);
    });   
      
    }

    fetchMyAPI()
    
    
    
   // structure.push(od);
   
    
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  },[]);

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {Listmenu != null && Listmenu.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
