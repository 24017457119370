import React , {useState} from "react";
import {encode , decode } from 'jwt-simple';
import { Grid,TextField ,Button,LinearProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from "react-toastify";



// styles
import "react-toastify/dist/ReactToastify.css";
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";






const useStyles2 = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ChangePasswordPage(props) {
  var classes = useStyles(); 
  var css =  useStyles2(); 
  const Users = props;
  
  const [Passwords, setPasswordOld] = useState("");
  const [PasswordsNew, setPasswordNew] = useState("");
  const [PasswordsNews, setPasswordNews] = useState("");
 // const[historyCashin,sethistoryCasin] = useState(null);


  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);

  // var [activeTabId, setActiveTabId] = useState(0);
 


 


  function ChangePasswords() { 
    setIsLoading(true);
    if(PasswordsNew!=PasswordsNews){
             toast.error("Password is not Match.");
         
         // dispatch({ type: "LOGIN_FAILURE" });
         
          setIsLoading(false);

    }
    else{
   
    const data = 
              {
                "userid": Users.data.userid,
                "password_old": Passwords,
                "password_new": PasswordsNews,
                "key" : Users.data.key
              }
              
   

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    
     fetch('/PostUpdatePasswordByUser_V2', requestOptions)
      .then((response) => response.json())
      .then((responsejson) =>{
       
        const result =responsejson;
    
        if(result.resultCode=="200"){
                       
          
            setIsLoading(false)
           // dispatch({ type: 'LOGIN_SUCCESS' })
         
            toast.success("Operation Success");
    
           //alert('Success.')
           
            
    
        }
        else{
    
         // console.log(JSON.stringify(result));
         toast.error(result.resultDes);
         
         // dispatch({ type: "LOGIN_FAILURE" });
         
          setIsLoading(false);
        }    
  
      });
    }
  
    
  }




  return (
    <>
      <PageTitle title="Change Password" />    
      
      <ToastContainer
         
        position="top-right"      
        autoClose={5000}
       
        
        />
      
      

        <Grid item xs={12} md={12}>
        
         

          
        </Grid>
        <Grid container spacing={12}>
        <Grid item xs={12} md={12}>
        <Widget title="Please input your old password and new password. " disableWidgetMenu>
          <form className={classes.root} noValidate autoComplete="off">
          <div>       
            <TextField
            id="outlined-basic" label="Current Password" onChange={e => setPasswordOld( e.target.value)} variant="outlined"
              type="password"    
              required        
            
            />       
          </div>
          <br/>
          <div>       
            <TextField
            id="outlined-basic" label="New Password" onChange={e => setPasswordNew( e.target.value)} variant="outlined"
              type="password"    
              required        
            
            />       
          </div>
          <br/>
         <div>
        <TextField
          required
          type="password"    
          id="outlined-basic" label="Confirm New Password" onChange={e => setPasswordNews( e.target.value)} variant="outlined"
         
         
         
        />
       
      </div>
      <br/>
      <div>
      <Button style={{width:'220px'}} onClick={ChangePasswords} variant="contained" color="primary" >
        submit 
      </Button>
      </div>
        </form>
        
          </Widget>        
        </Grid>
       
        </Grid>
        { isLoading == true ? <LinearProgress /> :  <br/>}
       
        
       
    </>
  );



}




