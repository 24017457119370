import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Link
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import {encode , decode } from 'jwt-simple';
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.png";
// context


function ResetPassword(props) {
  var classes = useStyles(); 
  const pros = props.location.state;
  // global


  // local
  
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);  
  var [newPassword, setnewPassword] = useState("");
  var [newPassword2, setnewPassword2] = useState("");


  function Reset(){
    if(newPassword!=newPassword2){
      alert('Password is not Match');
      return 0;
    }
    setIsLoading(true) 
    const data =  {
                "usercode": pros.usercode,
                "password": newPassword,
                "password_confirm": newPassword2,
                "sms_otp" : pros.opt
              }
        

  
 
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  
   fetch('/ConfirmUpdatePassword_V2', requestOptions)
    .then((response) => response.json())
    .then((responsejson) =>{
     
      const result =responsejson;
 
      if(result.resultCode=="200"){
        
         
          setIsLoading(false);        
          alert(result.resultCode)
          props.history.push("/login")

  
      }
      else{
        setIsLoading(false);
        alert(result.resultCode)
        props.history.push("/login")
           
        
      }    

    });


  }

 


  return (
    <Grid container className={classes.container}>

      <div className={classes.logotypeContainer}>
      
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>M-Money Agent</Typography>
      </div>
      <div className={classes.formContainer}> 
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Reset Password" classes={{ root: classes.tab }} />
          
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
                            
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
             
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={newPassword}
                onChange={e => setnewPassword(e.target.value)}
                margin="normal"
                placeholder="New Password"
                type="password"
                fullWidth
                />
            
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={newPassword2}
                onChange={e => setnewPassword2(e.target.value)}
                margin="normal"
                placeholder="Cofirm Password"
                type="password"
                fullWidth
              />
                

              
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      newPassword.length === 0 && newPassword2.length === 0
                    }
                    onClick={Reset}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Submit
                  </Button>
                )}
               
                
              </div>
            </React.Fragment>
          )}
        
           
        
        </div>
        <Typography color="primary" className={classes.copyright}>
        © 2020-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://mmoney.la" rel="noopener noreferrer" target="_blank">Lao Mobile Money</a>, LLC. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(ResetPassword);
